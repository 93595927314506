.three-bars-modal {
  display: flex;
  grid-column-gap: 12px;
  column-gap: 12px;
  padding: 4px;
  justify-content: space-between;
}

.three-bars-modal > div {
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  font-size: 7px;
  width: 100%;
  position: relative;
}
.three-bars-modal > div > div {
  background-color: white;
  height: 75px;
  width: 40px;
  display: grid;
  align-content: space-around;
  justify-content: center;
}
.three-bars-modal > div > div > svg {
  width: 25px;
  height: 25px;
  margin: auto;
}

.checked {
  background-color: white !important;
}

.svgContainer-modal.TeamLeader,
.svgContainer-modal.Blue,
.svgContainer-modal.Red,
.svgContainer-modal.Yellow,
.svgContainer-modal.White,
.svgContainer-modal.Green {
  width: 25px;
  margin: auto;
}
.svgContainer-modal.TeamLeader svg,
.svgContainer-modal.Blue svg,
.svgContainer-modal.Red svg,
.svgContainer-modal.Yellow svg,
.svgContainer-modal.White svg,
.svgContainer-modal.Green svg {
  width: 25px;
  height: 25px;
  margin: auto;
}
.svgContainer-modal.TeamLeader svg > g > g > * {
  fill: #899196 !important;
}
.svgContainer-modal.Blue svg > g > g > * {
  fill: #005b96 !important;
}
.svgContainer-modal.Red svg > g > g > * {
  fill: #d01349 !important;
}
.svgContainer-modal.Yellow svg > g > g > * {
  fill: #fcb813 !important;
}
.svgContainer-modal.White svg > g > g > * {
  fill: #899196 !important;
}
.svgContainer-modal.Green svg > g > g > * {
  fill: #00a94f !important;
}
