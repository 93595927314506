.left-bar {
  width: 115px;
  padding-top: 70px;
  padding-bottom: 18px;
  margin-right: 74px;
}
.left-bar > div {
  background-image: url("../images/leftbar-bg.png");
  padding: 10px 15px;
}
.teamscore {
  text-align: center;
  font-size: 13px;
  color: white;
  font-family: "TradeGothicBold";
  letter-spacing: 1px;
}

.side-bar-block {
  display: flex;
  flex-direction: column;
  color: #231f20;
  text-align: center;
  font-size: 6px;
  height: 97px;
  width: 51px;
  background: white;
  border: 5px solid white;
  align-items: center;
}
.side-bar-block > div > svg {
  width: 25px;
  height: 25px;
}
.side-bar-block > div > span {
  font-size: 8px;
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  margin-bottom: -11px;
}
.invested {
  display: flex;
  bottom: 0px;
  position: absolute;
  margin-bottom: -587px;
  left: 0;
  flex-direction: column;
  background-image: url("../images/leftbar-bg.png");
  padding: 10px 15px;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 100;
}
.invested-title {
  font-size: 13px;
  color: white;
  padding-bottom: 5px;
  margin-top: -5px;
  font-family: "TradeGothicBold";
}
.invested-bar {
  background: #c1c6c9;
  padding: 27.6px 6px !important;
}
.invested-bar > svg {
  width: 32px;
  height: 25px;
}
.invested-bar > svg {
  width: 32px;
  height: 25px;
}
.invested-bar > svg > g > g {
  fill: white;
}
.invested-bar > svg > g > g {
  fill: white;
}
.invested-bar > svg > g > g > path {
  fill: white;
}
.invested-bar > svg > g > g > path {
  fill: white;
}
.three-bars > div:first-of-type > .invested-bar,
.three-bars > div:last-of-type > .invested-bar {
  border-right: 5px solid white !important;
}

.team-score-badge {
  zoom: 0.35;
  margin-top: 40px;
}

@-moz-document url-prefix() {
  .team-score-badge {
    -moz-transform: scale(0.35) translate(-170px, 120px);
    margin-top: -70px;
  }
}

.side-bar-block:nth-of-type(1) {
  border: 5px solid #5b0865;
  border-bottom: 14px solid #5b0865 !important;
  background: #bfc5c9 !important;
}
.side-bar-block:nth-of-type(1) > span {
  color: black;
}
.side-bar-block:nth-of-type(1) div > svg > g > g > path {
  fill: white;
}
.side-bar-block:nth-of-type(2) {
  border: 5px solid #d01349;
  border-bottom: 14px solid #d01349 !important;
}
.side-bar-block:nth-of-type(2) div > svg > g > g > path {
  fill: #d01349;
}
.side-bar-block:nth-of-type(3) {
  border: 5px solid #00a84f;
  border-bottom: 14px solid #00a84f !important;
}
.side-bar-block:nth-of-type(3) div > svg > g > g > path {
  fill: #00a84f;
}
.side-bar-block:nth-of-type(4) {
  border: 5px solid #005b96;
  border-bottom: 14px solid #005b96 !important;
}
.side-bar-block:nth-of-type(4) div > svg > g > g > path {
  fill: #005b96;
}
.side-bar-block:nth-of-type(5) {
  border: 5px solid #fcb813;
  border-bottom: 14px solid #fcb813 !important;
}
.side-bar-block:nth-of-type(5) div > svg > g > g > path {
  fill: #fcb813;
}
.side-bar-block:nth-of-type(6) {
  border: 5px solid white;
  border-bottom: 14px solid white !important;
}
.side-bar-block:nth-of-type(6) > div > span {
  color: #899196;
}

.side-bar-block:nth-of-type(7) {
  border: 5px solid #8e9396;
  border-bottom: 14px solid #8e9396 !important;
}
.side-bar-block:nth-of-type(7) > div > span {
  color: #899196;
}

.side-bar-block:nth-of-type(8) {
  border: 5px solid #6a0d6f;
  border-bottom: 14px solid #6a0d6f !important;
  visibility: hidden;
}
.side-bar-block:nth-of-type(8) > div > span {
  color: #899196;
  visibility: hidden;
}

.team-score-badge {
  zoom: 0.35;
  margin-top: 40px;
}

@-moz-document url-prefix() {
  .team-score-badge {
    -moz-transform: scale(0.35) translate(-170px, 120px);
    margin-top: -70px;
  }
}

.team-score-value {
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: darkred;
  font-size: 25px;
  font-weight: 600;
}
.d-grid {
  display: grid;
}

.cards-moveLeft {
  margin-left: 188px;
}
