.impact-visual-dots {
	display: flex;
    color: white;
    font-size: 8px;
    border-radius: 9px;
    width: 18px;
    height: 18px;
    position: absolute;
    top: -5px;
    right: -9px;
    align-items: center;
    justify-content: center;
}
.impact-visual-dots.red {
	background-color: red;
}
.impact-visual-dots.green {
	background-color: green;
}