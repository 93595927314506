.right-bar {
  position: absolute;
  margin-right: 40px;
  right: 0;
  z-index: 5;
}
.right-bar > div {
  border: 2px solid #bfc5c9;
  width: 150px;
  cursor: pointer;
}
.central-board-img {
  width: 100px;
  margin-top: 10px;
  height: 55px;
  border: 3px solid white;
}
.board-modal > .modal-dialog {
  max-width: 70vw !important;
}
.board-modal > .modal-dialog > .modal-content {
  padding: 10px;
  font-size: 0.8em;
  min-height: 70vh;
  background-image: url("../images/centeralBoardBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.board-modal-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 35px;
}
.mini-board-team {
  background-color: #a8afb5;
  width: 300px;
  height: 360px;
  padding: 5px;
}
.mini-board-team > div {
  position: relative;
  display: flex;
  margin-bottom: 7px;
}
.mini-board-team > div > div:first-of-type {
  width: 30%;
  margin-right: 15px;
  text-align: end;
  font-size: 13px;
  font-weight: 600;
  color: white;
}
.mini-board-team > div > div:nth-of-type(2) {
  width: 65%;
}
.mini-board-rings {
  display: flex;
}
.mini-board-rings > div {
  background: white;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  margin-right: 4px;
}
.mini-board-rings > div > .ring {
  width: 7px !important;
  height: 7px !important;
}
.mini-board-rings > div > div {
  border-color: rgb(236, 0, 140);
}
.ringChecked {
  background: rgb(236, 0, 140);
}
.svg-container {
  width: 100% !important;
  height: 100px !important;
}
.svg-container > span {
  font-size: 24px;
  color: #899196;
}
.svg-container > div {
  height: 40px !important;
  width: 40px !important;
}
.svg-container > div > svg {
  width: 40px !important;
  height: 40px !important;
}

.team-name {
  font-size: 23px;
  font-weight: 700;
  color: white;
  margin-left: 20px;
}

.w-48 {
  width: 48% !important;
}
