h1 {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    color: #002858;
}

.cards-grid {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.cards-grid .card-item {
    margin: 10px;
    background-color: #002858;
    width: 120px;
	height: 120px;
	border: 1px solid #fff;
	border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blurCard {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    opacity: 0.3;
    width: 80px;
}

.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.card-content .card-number {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}