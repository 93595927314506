.bg {
  position: fixed;
  top: 70px;
  left: 0;

  min-width: 100%;
  min-height: 100%;
  z-index: -10;
}
.wrapper {
  width: 90%;
  display: flex;
  justify-content: center;
}
.containers {
  position: relative;
  width: 1092px;
  margin-bottom: -600px;
}

.containers::after {
  content: "";
  top: -50px;
  left: -111px;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 131px;
  background-image: url("./assets/images/world.png");
}

.relationship-card {
  padding: 4px 19px;
  height: 50px;
  padding-bottom: 9px;
  font-family: "TradeGothicBold";
  text-align: start;
  position: relative;
  margin-bottom: 5px;
  background-color: white;
  display: flex;
  column-gap: 10px;
  border: 5px solid #bfc5c9;
  z-index: 2;
  box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 55%);
}
.relationship {
  position: absolute;
}
.relationship::after {
  width: 9px;
  height: 273px;
  box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 40%);
  content: "";
  position: absolute;
  left: 50%;
  top: 50px;
  z-index: -1;
  transform-origin: top center;
}
.relationship::before {
  bottom: 50%;
  width: 9px;
  box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 40%);
  height: 203px;
  content: "";
  position: absolute;
  left: 50%;
  margin-bottom: 3px;
  transform-origin: bottom center;
}

.relationship-card > span {
  font-size: 7px;
  color: black;
  background-color: #bfc5c9;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  margin-bottom: -4px;
  padding-top: 1px;
}

.right-arrow {
  margin-left: -13px;
  transform: rotateY(180deg);

  height: 14px;
  margin-right: -9px;
}
.left-arrow {
  height: 9px;
  margin-left: -9px;
}
.person {
  width: 9px;
}
.person > svg > g > g > * {
  width: 9px;
}

.relation-value {
  position: absolute;
  top: 4px;
  font-size: 20px;
  width: 100%;
  left: 0;
}

.happy-face {
  width: 20px;
  position: absolute;
  top: -8px;
  left: -10px;
}

/*  */
.zoom-50 {
	zoom: 50%;
	-moz-transform: scale(0.5);
}
.zoom-60 {
	zoom: 60%;
	-moz-transform: scale(0.6);
}
.zoom-70 {
	zoom: 70%;
	-moz-transform: scale(0.7);
}
.zoom-80 {
	zoom: 80%;
	-moz-transform: scale(0.8);
}
.zoom-90 {
	zoom: 90%;
	-moz-transform: scale(0.9);
}
.zoom-100 {
	zoom: 100%;
	-moz-transform: scale(1);
}
.zoom-110 {
	zoom: 110%;
	-moz-transform: scale(1.1) translate(5%, 5%);
}
.zoom-120 {
	zoom: 120%;
	-moz-transform: scale(1.2) translate(10%, 10%);
}
.zoom-130 {
	zoom: 130%;
	-moz-transform: scale(1.3) translate(15%, 15%);
}
.zoom-140 {
	zoom: 140%;
	-moz-transform: scale(1.4) translate(20%, 20%);
}
.zoom-150 {
	zoom: 150%;
	-moz-transform: scale(1.5) translate(25%, 25%);
}
.relation-card-value {
  bottom: 16px;
  right: 0;
  position: absolute;
  z-index: 5;
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #d01349;
}
