.character-profile-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	height: 100px;
	background-color: #c0cdde;
	box-shadow: 0px 4px 10px #ccc;
    position: relative;
    z-index: 1;
}
.character-profile-body {
	background-color: #FFFFFF;
	padding: 25px;
	height: 400px;
	overflow-y: auto;
}
.field-name {
	width: 200px;
	font-size: 16px;
}
.field-value {
	font-size: 18px;
	font-weight: bold;
}

.information-title {
	font-size: 25px;
	font-weight: bold;
}
.characterName {
	font-size: 22px;
	font-weight: bold;
}
.characterName.red {
	color:  rgb(208, 19, 73);
}
.characterName.green {
	color: rgb(0, 169, 79);
}
.characterName.yellow {
	color: rgb(252, 184, 19)
}
.characterName.white {
	color: white
}
.characterName.blue {
	color: rgb(0, 91, 150);
}
.characterName.teamleader {
	color: rgb(137, 145, 150);
}


.table-row {
	height: 50px;
}
