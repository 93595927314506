.customer-cards {
	display: flex;
	color: white;
	justify-content: space-between;
	padding: 20px;
	/*grid-template-columns: repeat(2, auto);
	grid-template-rows: repeat(2, 90px);*/
	font-weight: 700;
	z-index: 10;
	margin-top: 80px;
	margin-left: 40px;
    margin-right: 40px;
	max-width: calc(100% - 80px);
    overflow-x: auto;
	position: fixed;
}
.customer-cards > div {
	display: flex;
	min-width: 300px;
	height: 85px;
	padding: 7px;
	font-size: 12px;
	background-image: url("../images/centeralBoardBg.png");
	border: 1px solid slategrey;
	margin-left: 15px;
	margin-right: 15px;
	flex-shrink: 0;
}

.customerCardValueContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 67%;
}

.customer-card-value-row {
	display: flex;
	flex-direction: row;
}