.circle {
  font-family: "TradeGothicBold";
  position: absolute;
  top: 0;
  left: auto;
  z-index: 2;
  box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 55%);
}
.circle > div {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.rectangle {
  height: 26px;
  background-color: white;
  margin: 1px auto;
  padding: 2px 0;
  display: flex;
  font-size: 0.4em;
  width: 100%;
  padding-left: 5px;
  flex-direction: column;
  position: relative;
  color: black;
}
.rectangle > div {
  display: flex;
  width: 60%;
}
.three-bars {
  display: flex;
  margin: 1px 0;
  column-gap: 2px;
  justify-content: space-between;
}

.three-bars > div {
  display: flex;
  flex-direction: column;
  color: #231f20;
  text-align: center;
  font-size: 6px;
  height: 97px;
  width: 51px;
  background: white;
  position: relative;
}
.three-bars > div > span {
  padding: 2px 0;
}
.svgContainer,
.three-bars > div > div,
.three-bars > div:nth-of-type(3) > div > div,
.three-bars > div:nth-of-type(4) > div > div {
  display: grid;
  align-content: center;
  justify-content: center;
  height: 100%;
  border: 5px solid white;
  border-bottom: 0;
}
.three-bars > div:nth-of-type(3) > div,
.three-bars > div:nth-of-type(4) > div {
  display: contents;
}

.three-bars > div > div > div > svg {
  width: 32px;
  height: 25px;
}
.three-bars > div > div > svg {
  width: 32px;
  height: 25px;
}
.three-bars > div > div > div > svg > g > g {
  fill: white;
}
.three-bars > div > div > svg > g > g {
  fill: white;
}
.three-bars > div > div > div > svg > g > g > path {
  fill: white;
}
.three-bars > div > div > svg > g > g > path {
  fill: white;
}

.svgContainer.TeamLeader {
  background-color: #bfc3c5 !important;
}
.avatar.TeamLeader {
  background-color: #899196 !important;
}

.svgContainer.Blue {
  background-color: #7497c2 !important;
}
.avatar.Blue {
  background-color: #005b96 !important;
}
.svgContainer.Red {
  background-color: #e68f8e !important;
}
.avatar.Red {
  background-color: #d01349 !important;
}
.svgContainer.Yellow {
  background-color: #f9d88f !important;
}
.avatar.Yellow {
  background-color: #fcb813 !important;
}
.svgContainer.White,
.avatar.White {
  background-color: gray !important;
}

.svgContainer.Green {
  background-color: #88cc9c !important;
}
.avatar.Green {
  background-color: #00a94f !important;
}

.rings {
  position: absolute;
  bottom: 2px;
  right: 0;
}
.ring {
  width: 7px;
  height: 6px;
  border-radius: 100%;
  border: 1px solid #d01349;
  position: relative;
}

.white {
  border: 1px solid #8a9298 !important;
  padding: 2px 4px 0 4px !important;
}
.white:nth-of-type(3) > div {
  display: block !important;
  border: 0 !important;
}
.white:nth-of-type(3) > div > div,
.white > div {
  border: 1px solid #8a9298 !important;
  background: white !important;
  opacity: 1 !important;
}
.white:nth-of-type(3) > div > div > svg > g > g,
.white > div > svg > g > g {
  fill: #8a9298 !important;
}
.white:nth-of-type(3) > div > div > svg > g > g > path,
.white > div > svg > g > g > path {
  fill: #8a9298 !important;
}

.avatar {
  position: absolute;
  top: -6px;
  left: -9px;
  width: 22px;
  border-radius: 100%;
  z-index: 2;
  height: 22px;
  display: flex;
  justify-content: center;
}
.avatar > svg {
  width: 20px;
}
.avatar > svg > g > g > path {
  fill: white;
}
.avatar.White > svg > g > g > path {
  fill: #8a9298;
}
.checked {
  background-color: #d01349;
}
.single-circle-value {
  font-size: 24px;
  color: #808080;
}
